import Router, { useRouter } from 'next/router';
import { useEffect } from 'react';
import useStickyRef from "./useStickyRef";
const useStickyScrollPosition = function () {
  let {
    shouldSave,
    shouldRestore
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const router = useRouter();
  const {
    pathname
  } = router || {};
  const [scrollPositionRef, setScrollPositionRef] = useStickyRef({
    initialValue: 0,
    defaultValue: 0,
    key: `stickyScroll_${pathname}`
  });
  useEffect(() => {
    const saveHandler = url => {
      if (!shouldSave || shouldSave({
        url
      })) {
        setScrollPositionRef(window.scrollY);
      } else {
        setScrollPositionRef(0);
      }
    };
    const loadHandler = () => {
      const previousPath = window.localStorage.getItem('stickyScrollPreviousPath');
      const scrollPosition = scrollPositionRef.current;
      if (typeof scrollPosition === 'number' && scrollPosition && (!shouldRestore || shouldRestore({
        previousPath
      }))) {
        window.scrollTo(0, scrollPosition);
      }
    };
    Router?.events.on('routeChangeStart', saveHandler);
    Router?.events.on('routeChangeComplete', loadHandler);
    return () => {
      Router?.events.off('routeChangeStart', saveHandler);
      Router?.events.off('routeChangeComplete', loadHandler);
    };
  }, [Router, shouldSave, shouldRestore]);
};
const initialize = function () {
  let {
    key = 'stickyScrollPreviousPath'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let previousPath = '';
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(key, '');
  }
  const onRouteChangeStart = () => {
    previousPath = Router?.pathname;
  };
  const onRouteChangeComplete = () => {
    window.localStorage.setItem(key, previousPath);
  };
  Router?.events.on('routeChangeStart', onRouteChangeStart);
  Router?.events.on('routeChangeComplete', onRouteChangeComplete);
};
useStickyScrollPosition.initialize = initialize;
export default useStickyScrollPosition;