import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { get } from 'lodash';
const FormData = require('form-data');
export const scrollIntoView = function (selector) {
  let {
    offsetY = 0,
    offsetX = 0,
    behavior = 'smooth'
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const element = document.querySelector(selector);
  if (element) {
    const {
      scrollY,
      scrollX
    } = window;
    const {
      top,
      left
    } = element.getBoundingClientRect();
    const targetScrollY = top + scrollY + offsetY;
    const targetScrollX = left + scrollX + offsetX;
    window.scrollTo({
      top: targetScrollY,
      left: targetScrollX,
      behavior
    });
  }
};
export const isValidURL = str => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};
export const loadImages = async urls => {
  return Promise.all((urls || []).map(async (url, index) => {
    return new Promise(resolve => {
      const img = new Image();
      img.onload = () => resolve({
        url,
        index
      });
      img.onerror = () => resolve({
        url,
        index,
        errored: true
      });
      img.src = url;
    });
  }));
};
export const toRem = function (value) {
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  if (typeof value === 'string') {
    if (value.endsWith('rem')) {
      return value;
    }
    const valueAsNumber = (() => {
      if (value.endsWith('px')) {
        return Number.parseFloat(value);
      }
      return Number(value);
    })();
    if (Number.isNaN(valueAsNumber)) {
      return value;
    }
    return `${valueAsNumber / context}rem`;
  }
  if (typeof value === 'number') {
    return `${value / context}rem`;
  }
  return value;
};
export const toEm = function (value) {
  let context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 16;
  if (typeof value === 'string') {
    if (value.endsWith('em')) {
      return value;
    }
    const valueAsNumber = (() => {
      if (value.endsWith('px')) {
        return Number.parseFloat(value);
      }
      return Number(value);
    })();
    if (Number.isNaN(valueAsNumber)) {
      return value;
    }
    return `${valueAsNumber / context}em`;
  }
  if (typeof value === 'number') {
    return `${value / context}em`;
  }
  return value;
};
export const extractMIMETypeFromBase64 = base64 => {
  return base64?.match(/^data:(\S+\/\S+);base64,/)[1];
};
export const getBase64 = async function (input) {
  let {
    extractMIMEType = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const handleOutput = output => {
    if (extractMIMEType) {
      return {
        output,
        MIMEType: extractMIMETypeFromBase64(output)
      };
    }
    return output;
  };
  if (!input || /^data:\S+;base64,.+/.test(input)) {
    return Promise.resolve(handleOutput(input));
  }
  if (input instanceof File) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(handleOutput(reader.result));
      });
      reader.readAsDataURL(input);
    });
  }
  return fetch(input).then(res => res.blob()).then(blob => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(handleOutput(reader.result));
      });
      reader.readAsDataURL(blob);
    });
  });
};
export const base64ToFile = async function (input) {
  let {
    filename,
    extension,
    MIMEType,
    defineProperties
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return fetch(input).then(res => res.blob()).then(blob => {
    return new File([blob], `${filename}.${extension}`, {
      type: MIMEType
    });
  }).then(file => {
    if (defineProperties) {
      Object.defineProperties(file, defineProperties);
    }
    return file;
  });
};
export const withoutNullValues = function (val) {
  let {
    filterArray = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (val == null) {
    return null;
  }
  if (Array.isArray(val)) {
    const newValue = val.map(withoutNullValues);
    if (filterArray) {
      return newValue.filter(e => e != null);
    }
    return newValue;
  }
  if (typeof val === 'object') {
    return Object.entries(val).reduce((acc, _ref) => {
      let [key, value] = _ref;
      const newValue = withoutNullValues(value);
      if (newValue == null) {
        return acc;
      }
      return _objectSpread(_objectSpread({}, acc), {}, {
        [key]: newValue
      });
    }, {});
  }
  return val;
};
export const getObjectKeyPaths = (obj, prefix) => {
  if (!obj) {
    return [];
  }
  const normalizedPrefix = (() => {
    if (prefix == null) {
      return [];
    }
    if (Array.isArray(prefix)) {
      return prefix;
    }
    return [prefix];
  })();
  if (typeof obj === 'object') {
    return Object.entries(obj).reduce((acc, _ref2) => {
      let [key, value] = _ref2;
      const adjustedKey = Array.isArray(obj) ? Number(key) : key;
      if (Array.isArray(value)) {
        if (value.length === 0) {
          return [...acc, [...normalizedPrefix, adjustedKey]];
        }
        const paths = value.map((e, index) => {
          return getObjectKeyPaths(e, [...normalizedPrefix, adjustedKey, index]).flat(Infinity);
        });
        return [...acc, ...paths];
      }
      if (typeof value === 'object' && value) {
        const path = getObjectKeyPaths(value, [...normalizedPrefix, adjustedKey]);
        return [...acc, ...path];
      }
      return [...acc, [...normalizedPrefix, adjustedKey]];
    }, []);
  }
  return normalizedPrefix;
};
export const objectHasValues = (obj, keys) => {
  return keys.every(key => {
    return get(obj, key) != null;
  });
};
export const streamToBuffer = stream => {
  return new Promise((resolve, reject) => {
    const buffers = [];
    stream.on('data', data => {
      buffers.push(data);
    });
    stream.on('end', () => {
      resolve(Buffer.concat(buffers));
    });
    stream.on('error', reject);
  });
};
export const toFormData = function (obj) {
  let {
    formData = new FormData()
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // File instances are deferred to be the last to be appended as Skipper
  // expects them so.
  const files = [];
  Object.entries(obj).forEach(_ref3 => {
    let [key, value] = _ref3;
    // Omit null and undefined values as otherwise they are appended as strings.
    if (value == null) {
      return;
    }
    if (Array.isArray(value)) {
      if (key.endsWith('[]')) {
        if (typeof File !== 'undefined' && value instanceof File) {
          files.push({
            key,
            value
          });
        } else {
          value.forEach(e => formData.append(key, e));
        }
      } else {
        formData.append(key, JSON.stringify(value));
      }
    } else if (typeof File !== 'undefined' && value instanceof File) {
      files.push({
        key,
        value
      });
    } else if (typeof Buffer !== 'undefined' && value instanceof Buffer) {
      formData.append(key, value);
    } else if (typeof value === 'object') {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });
  files.forEach(_ref4 => {
    let {
      key,
      value
    } = _ref4;
    formData.append(key, value);
  });
  return formData;
};
export const settleInSequence = async funcs => {
  return funcs.reduce(async (acc, cur) => {
    await acc;
    return cur();
  }, Promise.resolve());
};
export const replaceLineBreaks = function (str) {
  let replacement = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ' ';
  return str?.replace(/(\r?\n|\r)+/g, replacement);
};

// Modified from http://detectmobilebrowsers.com/
export const isMobileBrowser = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const target = navigator.userAgent || navigator.vendor || window.opera;
  const regex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
  const regex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;
  return regex1.test(target) || regex2.test(target.substr(0, 4));
};

// Modified from http://detectmobilebrowsers.com/
export const isMobileOrTabletBrowser = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  const target = navigator.userAgent || navigator.vendor || window.opera;
  const regex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i;
  const regex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i;
  return regex1.test(target) || regex2.test(target.substr(0, 4));
};