var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {
  "id": {
    "deploy": {}
  }
};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import { getRunEnvironment } from "./utils/EnvUtils";
const SENTRY_ENVIRONMENT = getRunEnvironment();
if (process.env.SENTRY_DSN && SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.0
  });
}