import { ZONING_STATUS } from "./DixuConstants";
import humanize from '@jsdevtools/humanize-anything';
export const getTranslationOrOther = (value, translationKey, _ref) => {
  let {
    i18n,
    t
  } = _ref;
  if (!value) {
    return null;
  }
  if (!i18n.exists(`${translationKey}${value}`)) {
    return value;
  }
  return t(`${translationKey}${value}`);
};
export const translateArray = (array, key, _ref2) => {
  let {
    t,
    i18n
  } = _ref2;
  if (!array?.length) {
    return undefined;
  }
  return array?.map(value => getTranslationOrOther(value, key, {
    t,
    i18n
  })).join(', ');
};
export const translateOrNull = (value, _ref3) => {
  let {
    t,
    i18n
  } = _ref3;
  if (!i18n.exists(value)) {
    return null;
  }
  return t(value);
};
const HUMANIZE_SANITIZE_REGEX = /,(?=[^,]*$)/;
export const translateArrayWithHumanize = function (array, key, _ref4) {
  let {
    t,
    i18n
  } = _ref4;
  let emptyAsNull = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  let params = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  const {
    forceLowerCase
  } = params;
  if (!array?.length) {
    return emptyAsNull ? null : '';
  }
  const options = {
    conjunction: t(`common:And`).toLowerCase()
  };
  const values = array.map((value, index) => {
    const translation = translateOrNull(`${key}${value}`, {
      t,
      i18n
    });
    if (translation) {
      if (forceLowerCase) {
        return translation?.toLowerCase();
      }
      return index === 0 && translation ? translation : translation?.toLowerCase();
    }
    // Never lowercase custom values
    return value;
  });
  const humanized = humanize.list(values, options)?.replace(HUMANIZE_SANITIZE_REGEX, '');
  if (!humanized) {
    return emptyAsNull ? null : '';
  }
  return humanized;
};
export const getZoningStatus = _ref5 => {
  let {
    zoningStatus,
    t,
    i18n
  } = _ref5;
  if (!zoningStatus || !zoningStatus.length) {
    return null;
  }
  return translateArrayWithHumanize(zoningStatus, 'listing:ZoningStatusValues.', {
    t,
    i18n
  });
};
export const translateBoolean = (value, _ref6) => {
  let {
    t,
    i18n
  } = _ref6;
  if (!i18n.exists(`common:BooleanTypes.${String(value)}`)) {
    return null;
  }
  return t(`common:BooleanTypes.${String(value)}`);
};
export const getArea = area => area ? `${area} m²` : undefined;