import { useRef, useState } from 'react';
const useStateRef = function () {
  const {
    0: value,
    1: setValue
  } = useState(...arguments);
  const valueRef = useRef(value);
  valueRef.current = value;
  return [value, setValue, valueRef];
};
export default useStateRef;