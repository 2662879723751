// Downloads multiple files
// Note: browsers will show a notification prompting user to allow downloading of multiple files,
// files won't start downloading until user accepts
// eslint-disable-next-line import/prefer-default-export
export const DownloadAll = urlList => {
  urlList.forEach((url, index) => {
    setTimeout(() => {
      const link = document.createElement('a');
      link.setAttribute('download', null);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', url);
      link.click();
      document.body.removeChild(link);
    }, index * 500);
  });
};
export const downloadUrl = function (src) {
  let {
    downloadName,
    createObjectURL = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const link = document.createElement('a');
  link.setAttribute('download', downloadName || true);
  link.setAttribute('href', createObjectURL ? URL.createObjectURL(src) : src);
  link.click();
  link.remove();
};