import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["url", "headers", "extractData", "req", "res"],
  _excluded2 = ["req", "res", "params"],
  _excluded3 = ["listingID", "req", "res"],
  _excluded4 = ["req", "res"],
  _excluded5 = ["req", "res"],
  _excluded6 = ["listingID", "req", "res"],
  _excluded7 = ["listingID"],
  _excluded8 = ["req", "res"],
  _excluded9 = ["listingID", "req", "res"],
  _excluded10 = ["req", "res"],
  _excluded11 = ["listingID", "req", "res"],
  _excluded12 = ["listingID", "data"],
  _excluded13 = ["listingID"],
  _excluded14 = ["offerID"],
  _excluded15 = ["listingID", "data"],
  _excluded16 = ["counterOfferID", "data"],
  _excluded17 = ["offerID"],
  _excluded18 = ["offerID"],
  _excluded19 = ["offerID"],
  _excluded20 = ["offerID"],
  _excluded21 = ["listingID", "getPDFStream", "req", "res", "headers"],
  _excluded22 = ["listingID", "req", "res", "headers"],
  _excluded23 = ["listingID", "counterOfferID", "req", "res", "headers"],
  _excluded24 = ["listing", "allConditions"],
  _excluded25 = ["listingID", "coupon", "data"],
  _excluded26 = ["listingID", "data"],
  _excluded27 = ["listingID", "messageThreadID"],
  _excluded28 = ["listingID"],
  _excluded29 = ["listingID", "presentationID"],
  _excluded30 = ["listingID", "req", "res"],
  _excluded31 = ["listingID"],
  _excluded32 = ["listingID"],
  _excluded33 = ["filename", "listingID", "documentID"],
  _excluded34 = ["listingID"],
  _excluded35 = ["commissionID"],
  _excluded36 = ["listingID"],
  _excluded37 = ["listingID"],
  _excluded38 = ["listingID", "linkID"],
  _excluded39 = ["listingID"],
  _excluded40 = ["listingID"],
  _excluded41 = ["biddingID"],
  _excluded42 = ["selectedSlot"],
  _excluded43 = ["data"],
  _excluded44 = ["placeID"],
  _excluded45 = ["listingID"],
  _excluded46 = ["listingID"],
  _excluded47 = ["listingID"],
  _excluded48 = ["listingID", "realtorID", "data"],
  _excluded49 = ["listingID", "imageID", "data"],
  _excluded50 = ["req", "res", "params"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import axios from 'axios';
import { shuffle, uniqBy } from 'lodash';
import moment from 'moment';
import Router from 'next/router';
import { COUPON_TYPES, PURCHASE_OFFER_TYPE, SERVICE_PACKAGE } from "../utils/DixuConstants";
import { getPurchaseOfferFileName } from "../utils/DixuPurchaseOfferUtils";
import { downloadUrl } from "../utils/FileUtils";
import { getNumericPriceForCity } from "../utils/MoneyUtils";
import { streamToBuffer, toFormData } from "../utils/Utils";
export const getUser = _ref => {
  let {
    req
  } = _ref;
  if (typeof window !== 'undefined') {
    return window.__USER || req?.session?.passport?.user;
  }
  return req?.session?.passport?.user;
};
export const getCommonHeaders = _ref2 => {
  let {
    req
  } = _ref2;
  const headers = {};
  const user = getUser({
    req
  });
  const {
    token
  } = user || {};
  if (token) {
    headers.user = token;
  }
  return headers;
};
export const redirect = function (to) {
  let {
    status = 302,
    res,
    router = Router,
    hard = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (hard) {
    window.location.href = to;
  } else if (res) {
    res.writeHead(status, {
      Location: to,
      'Cache-Control': 'no-cache'
    });
    res.end();
  } else if (router) {
    router.push(to);
  }
  return undefined;
};
export const reload = async function () {
  let {
    hard
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (hard) {
    window.location.reload(true);
    return undefined;
  }
  return new Promise((resolve, reject) => {
    const routeChangeComplete = function () {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      resolve([...args]);
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeError);
    };
    const routeChangeError = function () {
      for (var _len2 = arguments.length, args = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        args[_key2] = arguments[_key2];
      }
      reject([...args]);
      Router.events.off('routeChangeComplete', routeChangeComplete);
      Router.events.off('routeChangeError', routeChangeError);
    };
    Router.events.on('routeChangeComplete', routeChangeComplete);
    Router.events.on('routeChangeError', routeChangeError);
    Router.push(Router.asPath, undefined, {
      scroll: false
    });
  }).catch(console.error);
};
export const clearSessionCookies = async _ref3 => {
  let {
    res
  } = _ref3;
  if (process.browser) {
    // Clear session cookies (have to do this since cant edit cookies clientside due to HttpOnly)
    await axios.get(`${process.env.WEB_URL}/api/clear-session`);
  } else if (res) {
    console.log('Session expired. Delete cookies serverside');
    res.clearCookie('connect.sid', {
      path: '/'
    });
    res.clearCookie('express:sess.sig', {
      path: '/'
    });
  }
  redirect('/login', {
    res,
    hard: !res
  });
};
export const request = async _ref4 => {
  let {
      url,
      headers: incomingHeaders,
      extractData = true,
      req,
      res
    } = _ref4,
    rest = _objectWithoutProperties(_ref4, _excluded);
  const headers = _objectSpread(_objectSpread({}, getCommonHeaders({
    req
  })), incomingHeaders);
  return axios(_objectSpread({
    baseURL: url.startsWith('/api/') ? undefined : process.env.API_URL,
    headers,
    url
  }, rest)).then(response => {
    if (extractData) {
      return response.data;
    }
    return response;
  }).catch(async error => {
    console.error(error, error?.response);
    const {
      response
    } = error || {};
    const {
      status
    } = response || {};
    if (status === 401) {
      await clearSessionCookies({
        res
      }).catch(() => {});
      return Promise.resolve();
    }
    return Promise.reject(error?.response || error);
  });
};
export const getErrorDescription = error => {
  console.error(error);
  const {
    status,
    statusText,
    data,
    message
  } = error?.response || error || {};
  const {
    details
  } = data || {};
  if (data) {
    if (typeof data === 'string') {
      return data;
    }
    if (typeof details === 'string') {
      return details;
    }
    if (typeof data === 'object') {
      try {
        return JSON.stringify(data, null, 2);
      } catch (_) {
        // NOOP
      }
    }
  }
  if (message) {
    return message;
  }
  if (status && statusText) {
    return `${statusText} (${status})`;
  }
  return undefined;
};
export const get = async (url, properties) => {
  return request(_objectSpread(_objectSpread({}, properties), {}, {
    method: 'get',
    url
  }));
};
export const post = async (url, properties) => {
  return request(_objectSpread(_objectSpread({}, properties), {}, {
    method: 'post',
    url
  }));
};
export const getAllPublicListings = _ref5 => {
  let {
      req,
      res,
      params
    } = _ref5,
    properties = _objectWithoutProperties(_ref5, _excluded2);
  return get('/dixu-listings', _objectSpread({
    req,
    res,
    params
  }, properties));
};
export const getPublicListing = _ref6 => {
  let {
      listingID,
      req,
      res
    } = _ref6,
    properties = _objectWithoutProperties(_ref6, _excluded3);
  return get(`/v3/dixu-listings/${listingID}`, _objectSpread({
    req,
    res
  }, properties));
};
export const getPurchaseOffersForUser = async _ref7 => {
  let {
      req,
      res
    } = _ref7,
    properties = _objectWithoutProperties(_ref7, _excluded4);
  const purchaseOffersData = await get('/dixu/dixu-purchase-offers', _objectSpread({
    req,
    res
  }, properties));
  return purchaseOffersData?.purchaseOffers || null;
};
export const getAllSalesListingsForUser = async _ref8 => {
  let {
      req,
      res
    } = _ref8,
    properties = _objectWithoutProperties(_ref8, _excluded5);
  const listings = await get('/listings', _objectSpread({
    req,
    res
  }, properties));
  return listings;
};

// TODO Optimize request.
export const getSalesListingForUser = async _ref9 => {
  let {
      listingID,
      req,
      res
    } = _ref9,
    properties = _objectWithoutProperties(_ref9, _excluded6);
  const listings = await getAllSalesListingsForUser(_objectSpread({
    req,
    res
  }, properties));
  const listing = listings?.find(data => data.id === listingID);
  if (!listing) {
    return null;
  }
  return listing;
};
export const publishListing = async _ref10 => {
  let {
      listingID
    } = _ref10,
    properties = _objectWithoutProperties(_ref10, _excluded7);
  return post(`/dixu/dixu-listings/${listingID}/publish`, properties);
};
export const getAllPurchaseListingsForUser = async _ref11 => {
  let {
      req,
      res
    } = _ref11,
    properties = _objectWithoutProperties(_ref11, _excluded8);
  const purchaseOffersData = await get('/dixu/dixu-purchase-offers', _objectSpread({
    req,
    res
  }, properties));
  const purchaseOffers = purchaseOffersData?.purchaseOffers || [];
  const messageThreads = purchaseOffersData?.messageThreads || [];
  const listings = await Promise.all(uniqBy([...purchaseOffers, ...messageThreads], 'dixuListing').map(async offer => {
    const {
      dixuListing
    } = offer;
    try {
      const listing = await get(`/dixu-listings/${dixuListing}/data`, {
        req,
        res
      });
      if (!listing) {
        return null;
      }
      return _objectSpread(_objectSpread({}, listing), {}, {
        purchaseOffers: purchaseOffers.filter(e => e.dixuListing === dixuListing),
        messageThreads: messageThreads.filter(e => e.dixuListing === dixuListing)
      });
    } catch (_) {
      console.error(`Error getting data for listing ${dixuListing}`);
      return null;
    }
  }));
  return listings.filter(Boolean);
};

// TODO Optimize request.
export const getPurchaseListingForUser = async _ref12 => {
  let {
      listingID,
      req,
      res
    } = _ref12,
    properties = _objectWithoutProperties(_ref12, _excluded9);
  const listings = await getAllPurchaseListingsForUser(_objectSpread({
    req,
    res
  }, properties));
  const listing = listings?.find(data => data.id === listingID);
  return listing || null;
};
export const createNewListing = async _ref13 => {
  let {
      req,
      res
    } = _ref13,
    properties = _objectWithoutProperties(_ref13, _excluded10);
  return post('/dixu-listings/create', _objectSpread({
    req,
    res
  }, properties));
};
export const getListingDraft = async _ref14 => {
  let {
      listingID,
      req,
      res
    } = _ref14,
    properties = _objectWithoutProperties(_ref14, _excluded11);
  return get(`/dixu-drafts/${listingID}`, _objectSpread({
    req,
    res
  }, properties));
};
export const updateListing = async _ref15 => {
  let {
      listingID,
      data
    } = _ref15,
    properties = _objectWithoutProperties(_ref15, _excluded12);
  return post(`/dixu-listings/${listingID}`, _objectSpread({
    data: _objectSpread(_objectSpread({}, data), {}, {
      tosAccepted: true
    })
  }, properties));
};
export const getPurchaseOffer = async _ref16 => {
  let {
      listingID
    } = _ref16,
    properties = _objectWithoutProperties(_ref16, _excluded13);
  return get(`/dixu-listings/${listingID}/purchase-offer`, properties);
};
export const getCounterPurchaseOfferDraft = async _ref17 => {
  let {
      offerID
    } = _ref17,
    properties = _objectWithoutProperties(_ref17, _excluded14);
  return get(`/dixu/dixu-purchase-offers/${offerID}/counter-offer-draft`, properties);
};
export const savePurchaseOfferDraft = async _ref18 => {
  let {
      listingID,
      data
    } = _ref18,
    properties = _objectWithoutProperties(_ref18, _excluded15);
  return post(`/dixu/dixu-listings/${listingID}/purchase-offer/save-draft`, _objectSpread({
    data
  }, properties));
};
export const saveCounterPurchaseOfferDraft = async _ref19 => {
  let {
      counterOfferID,
      data
    } = _ref19,
    properties = _objectWithoutProperties(_ref19, _excluded16);
  return post(`/dixu/dixu-purchase-offers/${counterOfferID}/update-counter-offer-draft`, _objectSpread({
    data
  }, properties));
};
export const acceptPurchaseOffer = async _ref20 => {
  let {
      offerID
    } = _ref20,
    properties = _objectWithoutProperties(_ref20, _excluded17);
  return post(`/dixu/dixu-purchase-offers/${offerID}/operate/accept`, properties);
};
export const declinePurchaseOffer = async _ref21 => {
  let {
      offerID
    } = _ref21,
    properties = _objectWithoutProperties(_ref21, _excluded18);
  return post(`/dixu/dixu-purchase-offers/${offerID}/operate/decline`, properties);
};
export const cancelPurchaseOffer = async _ref22 => {
  let {
      offerID
    } = _ref22,
    properties = _objectWithoutProperties(_ref22, _excluded19);
  return post(`/dixu/dixu-purchase-offers/${offerID}/operate/cancel`, properties);
};
export const markPurchaseOfferAsSeen = async _ref23 => {
  let {
      offerID
    } = _ref23,
    properties = _objectWithoutProperties(_ref23, _excluded20);
  return post(`/dixu/dixu-purchase-offers/${offerID}/seen`, properties);
};
export const signServiceAgreement = async _ref24 => {
  let {
      listingID,
      getPDFStream,
      req,
      res,
      headers
    } = _ref24,
    properties = _objectWithoutProperties(_ref24, _excluded21);
  const listing = await getListingDraft({
    listingID,
    req,
    res
  });
  const basePrice = getNumericPriceForCity(listing);
  if (!basePrice) {
    throw Error(`Invalid price "${basePrice}" for listing ${listingID}`);
  }
  const buffer = await streamToBuffer(await getPDFStream({
    listing
  }));
  const formData = toFormData({
    listingId: listingID,
    file: buffer
  });
  const {
    url
  } = await post('/signicat/sign-dixu-service-agreement', _objectSpread(_objectSpread({
    req,
    res
  }, properties), {}, {
    data: formData,
    headers: _objectSpread(_objectSpread({}, headers), formData.getHeaders())
  }));
  return url;
};
export const signPurchaseOffer = async _ref25 => {
  let {
      listingID,
      req,
      res,
      headers
    } = _ref25,
    properties = _objectWithoutProperties(_ref25, _excluded22);
  const listing = await get(`/dixu-listings/${listingID}/data`, {
    req,
    res
  });
  const purchaseOffer = await getPurchaseOffer({
    listingID,
    req,
    res
  });
  const formData = toFormData({
    listingId: listingID,
    purchaseOfferId: purchaseOffer.id,
    fileName: getPurchaseOfferFileName(listing),
    type: PURCHASE_OFFER_TYPE.INITIAL_OFFER
  });
  const {
    url
  } = await post('/signicat/sign-dixu-purchase-offer', _objectSpread(_objectSpread({
    req,
    res
  }, properties), {}, {
    data: formData,
    headers: _objectSpread(_objectSpread({}, headers), formData.getHeaders())
  }));
  return url;
};
export const signCounterPurchaseOffer = async _ref26 => {
  let {
      listingID,
      counterOfferID,
      req,
      res,
      headers
    } = _ref26,
    properties = _objectWithoutProperties(_ref26, _excluded23);
  const listing = await get(`/dixu-listings/${listingID}/data`, {
    req,
    res
  });
  const purchaseOffer = await get(`/dixu/dixu-purchase-offers/${counterOfferID}`, {
    req,
    res
  });
  const formData = toFormData({
    listingId: listingID,
    purchaseOfferId: purchaseOffer.id,
    fileName: getPurchaseOfferFileName(listing),
    type: PURCHASE_OFFER_TYPE.COUNTER_OFFER
  });
  const {
    url
  } = await post('/signicat/sign-dixu-purchase-offer', _objectSpread(_objectSpread({
    req,
    res
  }, properties), {}, {
    data: formData,
    headers: _objectSpread(_objectSpread({}, headers), formData.getHeaders())
  }));
  return url;
};
export const getPriceEstimate = _ref27 => {
  let {
      listing,
      allConditions = true
    } = _ref27,
    properties = _objectWithoutProperties(_ref27, _excluded24);
  const {
    type,
    listingType,
    municipality,
    lotOwnership,
    postalCode,
    currentlyRented,
    elevator,
    area,
    completeYear,
    roomCount,
    floor,
    floorCount,
    condition
  } = listing || {};
  const data = _objectSpread(_objectSpread({}, listing), {}, {
    ownership: lotOwnership,
    area,
    type: listingType,
    postcode: postalCode,
    year: completeYear ? moment(completeYear).format('YYYY') : undefined,
    rooms: roomCount,
    floornumber: floor,
    floors: floorCount,
    municipality,
    // material: ['wood', 'other', 'brick', 'concrete', 'grider', 'rock', 'siporex'],
    lift: elevator,
    balcony: balcony || glazedBalcony,
    rented: currentlyRented
  });
  const fetchForConditions = (() => {
    if (allConditions) {
      return ['EXCELLENT', 'GOOD', 'AVERAGE', 'BAD'];
    }
    if (condition === 'NEW') {
      return ['EXCELLENT'];
    }
    return [condition];
  })();
  return Promise.all(fetchForConditions.map(conditionValue => {
    return get(`/priceestimate/apartment`, _objectSpread(_objectSpread({}, properties), {}, {
      params: {
        data: _objectSpread(_objectSpread({}, data), {}, {
          condition: conditionValue
        })
      }
    }));
  }));
};
export const applyCouponToListing = async _ref28 => {
  let {
      listingID,
      coupon,
      data
    } = _ref28,
    properties = _objectWithoutProperties(_ref28, _excluded25);
  return post(`/coupon/validate/${coupon}`, _objectSpread({
    data: _objectSpread(_objectSpread({}, data), {}, {
      type: COUPON_TYPES.DIXU,
      dixuListing: listingID
    })
  }, properties));
};
export const sendNewListingMessage = async _ref29 => {
  let {
      listingID,
      data
    } = _ref29,
    properties = _objectWithoutProperties(_ref29, _excluded26);
  return post(`/dixu/dixu-listings/${listingID}/message`, _objectSpread({
    data: _objectSpread(_objectSpread({}, data), {}, {
      newMessage: true
    })
  }, properties));
};
export const sendListingMessage = async _ref30 => {
  let {
      listingID,
      messageThreadID
    } = _ref30,
    properties = _objectWithoutProperties(_ref30, _excluded27);
  return post(`/dixu/dixu-listings/${listingID}/thread/${messageThreadID}/message`, _objectSpread({}, properties));
};
export const createNewPresentation = async _ref31 => {
  let {
      listingID
    } = _ref31,
    properties = _objectWithoutProperties(_ref31, _excluded28);
  return post(`/dixu/dixu-listings/${listingID}/presentation`, properties);
};
export const cancelPresentation = async _ref32 => {
  let {
      listingID,
      presentationID
    } = _ref32,
    properties = _objectWithoutProperties(_ref32, _excluded29);
  return post(`/dixu/dixu-listings/${listingID}/presentation/${presentationID}/cancel`, properties);
};
export const getSalesContract = async _ref33 => {
  let {
      listingID,
      req,
      res
    } = _ref33,
    properties = _objectWithoutProperties(_ref33, _excluded30);
  return get(`/dixu-listings/${listingID.replace('.docx', '')}/sales-contract`, _objectSpread(_objectSpread({
    req,
    res
  }, properties), {}, {
    responseType: 'stream',
    extractData: false
  })).then(response => {
    res.header('Content-Disposition', 'attachment; filename="Kauppakirjaluonnos.docx"');
    response.data.pipe(res);
  });
};
export const getPurchaseOfferDocument = async _ref34 => {
  let {
    offerID,
    req,
    res
  } = _ref34;
  const purchaseOffer = await get(`/dixu/dixu-purchase-offers/${offerID}`, {
    req,
    res
  });
  const {
    id
  } = purchaseOffer;

  // If purchase offer has unsignedDocument, return it from API
  // Or if purchase offer is new with CRM buyers,
  return get(`/dixu/dixu-purchase-offers/${id}/download`, {
    req,
    res,
    responseType: 'stream',
    extractData: false
  }).then(response => {
    response.data.pipe(res);
  });
};
export const updateImages = async _ref35 => {
  let {
      listingID
    } = _ref35,
    properties = _objectWithoutProperties(_ref35, _excluded31);
  return post(`/dixu/images/update-images/${listingID}`, properties);
};

// TODO Optimize request.
export const getMessageThreadsForUser = async _ref36 => {
  let {
    req,
    res
  } = _ref36;
  const saleListings = await getAllSalesListingsForUser({
    req,
    res
  });
  const purchaseListings = await getAllPurchaseListingsForUser({
    req,
    res
  });
  const saleMessageThreads = saleListings?.flatMap(listing => {
    const {
      messageThreads
    } = listing;
    return messageThreads?.map(thread => {
      return _objectSpread(_objectSpread({}, thread), {}, {
        listing
      });
    });
  }).filter(Boolean) || [];
  const purchaseMessageThreads = purchaseListings?.flatMap(listing => {
    const {
      messageThreads
    } = listing;
    return messageThreads?.map(thread => {
      return _objectSpread(_objectSpread({}, thread), {}, {
        listing
      });
    });
  }).filter(Boolean) || [];

  // TODO
  // Do this sorting stuff in backend.
  return [...saleMessageThreads, ...purchaseMessageThreads].map(thread => {
    const {
      messages
    } = thread;
    const sortedMessages = messages?.sort((a, b) => {
      const {
        updatedAt: aUpdatedAt
      } = a;
      const {
        updatedAt: bUpdatedAt
      } = b;
      return moment(aUpdatedAt) - moment(bUpdatedAt);
    });
    return _objectSpread(_objectSpread({}, thread), {}, {
      messages: sortedMessages
    });
  }).sort((a, b) => {
    const {
      messages: aMessages
    } = a;
    const {
      messages: bMessages
    } = b;
    const aLatestMessage = aMessages[aMessages.length - 1];
    const bLatestMessage = bMessages[bMessages.length - 1];
    const {
      updatedAt: aUpdatedAt
    } = aLatestMessage;
    const {
      updatedAt: bUpdatedAt
    } = bLatestMessage;
    return moment(bUpdatedAt) - moment(aUpdatedAt);
  });
};
export const reservePhotographyTime = async _ref37 => {
  let {
      listingID
    } = _ref37,
    properties = _objectWithoutProperties(_ref37, _excluded32);
  return post(`/dixu/reserve-photography/${listingID}`, properties);
};
export const downloadListingDocument = async _ref38 => {
  let {
      filename,
      listingID,
      documentID
    } = _ref38,
    properties = _objectWithoutProperties(_ref38, _excluded33);
  const doc = await get(`/dixu/listings/${listingID}/download-document/${documentID}`, _objectSpread({
    responseType: 'blob'
  }, properties));
  downloadUrl(doc, {
    downloadName: filename,
    createObjectURL: true
  });
};
export const resendServiceAgreementLinks = _ref39 => {
  let {
      listingID
    } = _ref39,
    properties = _objectWithoutProperties(_ref39, _excluded34);
  return post(`/listings/resend-signicat-links/${listingID}`, properties);
};
export const resendCommissionLinks = _ref40 => {
  let {
      commissionID
    } = _ref40,
    properties = _objectWithoutProperties(_ref40, _excluded35);
  return post(`/commissions/resend-signicat-links/${commissionID}`, properties);
};
export const resendDescriptionAttachmentLinks = _ref41 => {
  let {
      listingID
    } = _ref41,
    properties = _objectWithoutProperties(_ref41, _excluded36);
  return post(`/description-attachment/resend-signicat-links/${listingID}`, properties);
};
export const registerUser = async properties => {
  return post(`/dixu/register`, properties);
};
export const updateUser = properties => {
  return post('/dixu/user/updateme', properties);
};
export const requestDocumentDownloadLink = async _ref42 => {
  let {
      listingID
    } = _ref42,
    properties = _objectWithoutProperties(_ref42, _excluded37);
  return post(`/dixu/dixu-listings/${listingID}/download-documents`, properties);
};
export const getListingDocuments = async _ref43 => {
  let {
      listingID,
      linkID
    } = _ref43,
    properties = _objectWithoutProperties(_ref43, _excluded38);
  return get(`/dixu/dixu-listings/${listingID}/documents/${linkID}`, properties);
};
export const sendLoanApplication = async _ref44 => {
  let {
      listingID
    } = _ref44,
    properties = _objectWithoutProperties(_ref44, _excluded39);
  return post(`/dixu/dixu-listings/${listingID}/loan-application`, properties);
};
export const getBiddingListing = async _ref45 => {
  let {
      listingID
    } = _ref45,
    properties = _objectWithoutProperties(_ref45, _excluded40);
  return get(`/dixu-listings/bidding/${listingID}`, properties);
};
export const followBidding = async _ref46 => {
  let {
      biddingID
    } = _ref46,
    properties = _objectWithoutProperties(_ref46, _excluded41);
  return post(`/dixu/bidding/follow/${biddingID}`, properties);
};
export const reservePrivatePresentation = async _ref47 => {
  let {
      selectedSlot
    } = _ref47,
    properties = _objectWithoutProperties(_ref47, _excluded42);
  return post(`/dixu/private-presentation/${selectedSlot}/reserve`, properties);
};
export const sendContactRequest = async _ref48 => {
  let {
      data
    } = _ref48,
    properties = _objectWithoutProperties(_ref48, _excluded43);
  return post(`/contact`, _objectSpread({
    data: _objectSpread(_objectSpread({}, data), {}, {
      type: 'dixucontactrequest'
    })
  }, properties));
};
export const getPlaceDetails = async _ref49 => {
  let {
      placeID
    } = _ref49,
    properties = _objectWithoutProperties(_ref49, _excluded44);
  return get(`/search/place/${placeID}`, properties);
};
export const getNotificationsInfo = async properties => {
  return get('/dixu/user/notifications', properties);
};
export const sendRealtorPriceEstimateWish = async _ref50 => {
  let {
      listingID
    } = _ref50,
    properties = _objectWithoutProperties(_ref50, _excluded45);
  return post(`/dixu/dixu-listings/${listingID}/realtor-price-estimate-wish`, properties);
};
export const selectRealtorSuggestions = async _ref51 => {
  let {
      listingID
    } = _ref51,
    properties = _objectWithoutProperties(_ref51, _excluded46);
  return post(`/dixu/realtor-suggestions/select/${listingID}`, properties);
};
export const rejectRealtorSuggestions = async _ref52 => {
  let {
      listingID
    } = _ref52,
    properties = _objectWithoutProperties(_ref52, _excluded47);
  return post(`/dixu/realtor-suggestions/reject/${listingID}`, properties);
};
export const acceptRealtorSuggestion = async _ref53 => {
  let {
      listingID,
      realtorID,
      data
    } = _ref53,
    properties = _objectWithoutProperties(_ref53, _excluded48);
  return post(`/dixu/realtor-suggestions/accept/${listingID}`, _objectSpread({
    data: _objectSpread(_objectSpread({}, data), {}, {
      realtorID
    })
  }, properties));
};
export const getPurchaseOfferPreviewPDF = async _ref54 => {
  let {
    id,
    listingId,
    type,
    buyer
  } = _ref54;
  if (!listingId) {
    throw Error('Missing listingId in getPurchaseOfferPreviewPDF');
  }
  return post(`/pdf-document/purchase-offer/${listingId}`, {
    data: {
      buyer,
      type,
      id
    },
    responseType: 'blob'
  });
};
export const updateListingImage = async _ref55 => {
  let {
      listingID,
      imageID,
      data
    } = _ref55,
    properties = _objectWithoutProperties(_ref55, _excluded49);
  return post(`/dixu/image/${imageID}/${listingID}`, _objectSpread({
    data: _objectSpread({}, data)
  }, properties));
};
export const getLatestListings = async _ref56 => {
  let {
      req,
      res,
      params
    } = _ref56,
    properties = _objectWithoutProperties(_ref56, _excluded50);
  return get('/latest-dixu-listings', _objectSpread({
    req,
    res,
    params
  }, properties));
};
export const getLatestBlogPosts = async () => {
  return [{
    thumbnail: '/images/blog_posts/post_6.jpg',
    header: 'Virtuaaliesittely – uusi tapa tutustua asuntoon',
    description: 'Tähän poikkeukselliseen aikaan käteväksi osoittautunut virtuaaliesittely mahdollistaa sen, että myytäviin kohteisiin voi tutustua turvallisesti. Mutta mistä muista syistä virtuaaliesittely on erityisen hyödyllinen? Lue kokonaisvaltainen esittelymme palvelusta.',
    href: 'https://blog.dixu.fi/virtuaaliesittely-uusi-tapa-tutustua-asuntoon/'
  }, {
    thumbnail: '/images/blog_posts/post_5.png',
    header: 'Asuntokauppa perhejuridiikan näkökulmasta – turvaa selustasi',
    description: 'Asuntokauppaan liittyy paljon juridiikkaa. On laadittava kauppakirja lakipykälien mukaan ja otettava huomioon tarvittavat seikat esimerkiksi kaupan ehtoihin liittyen. Varsinaisen asuntokaupan lisäksi on hyvä pysähtyä miettimään, mitä muutoksia tapahtuma tuo henkilökohtaiseen elämään.',
    href: 'https://blog.dixu.fi/asuntokauppa-perhejuridiikan-nakokulmasta-turvaa-selustasi/'
  }, {
    thumbnail: '/images/blog_posts/post_4.jpeg',
    header: 'Myydäkö asunto itse vai ottaa välittäjä? – näin asian ratkaisi Alex',
    description: 'Alex Rönnberg oli uuden tilanteen edessä. Asuntokauppoja hän oli tehnyt ennenkin, mutta pääosin ostajan roolissa. Rönnberg oli seurannut Dixun tuloa markkinoille ja ajatellut, että haluaa kokeilla sitä itsekin asunnon myynnin tullessa ajankohtaiseksi. Mutta myydäkö itse vai ottaako välittäjä?',
    href: 'https://blog.dixu.fi/myydako-asunto-itse-vai-ottaa-valittaja-nain-asian-ratkaisi-alex/'
  }];
};
export const getCustomerExperiences = async () => {
  const IMAGE_RESOURCES = ['/images/customer_experiences/listing_1.jpg', '/images/customer_experiences/listing_2.jpg', '/images/customer_experiences/listing_3.jpg', '/images/customer_experiences/listing_4.jpg', '/images/customer_experiences/listing_5.jpg', '/images/customer_experiences/listing_6.jpg', '/images/customer_experiences/listing_7.jpg', '/images/customer_experiences/listing_8.jpg', '/images/customer_experiences/listing_9.jpg', '/images/customer_experiences/listing_10.jpg', '/images/customer_experiences/listing_11.jpg', '/images/customer_experiences/listing_12.jpg', '/images/customer_experiences/listing_13.jpg', '/images/customer_experiences/listing_15.jpg'];
  const dixuAndMeExperiences = [{
    content: 'Näytöt olivat hauskoja ja pääsimme itse esittelemään mahdollisille ostajille omaa kotiamme. Mehän olemme niitä parhaita asiantuntijoita tässä asunnossa ja näytöissä käyneet arvostivat tätä kovasti.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Asunnon myynti sujui yli odotusten. Esittelyssä kävijät kehuivat kovasti virtuaaliesittelyä ja hyviä kuvia. Myös asunnon myyntiä suunnittelevat kaverini totesivat Dixun hyväksi tavaksi myydä asunto.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Pelkkiä ruusuja! Erittäin tyytyväinen Dixun asiantuntevaan palveluun.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Ylitti odotukset. Helppous, monipuolisuus, laatu, tieto sekä näkyvyys. Hienoa, että kaikki oli verkkosivuilla nähtävillä myös ostajan näkökulmasta.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Asunnosta otettujen valokuvien laatu on todella hyvä, kuten myös virtuaaliesittelyn. Lisäksi oli positiivinen yllätys saada tavallisten valokuvien lisäksi dronekuvia, suuret kiitokset siitä kuvaajalle! Kaikki sujui hyvin ja vastaukset kysymyksiini sain nopeasti.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Erinomainen ja nopea asiakaspalvelu, vastaukset kysymyksiin sai heti. Toimiva alusta. Miinusta siitä, että ostajat joutuivat luomaan tilin sivuille allekirjoittaakseen ostotarjouksen. Muuten suosittelen ehdottomasti Dixua muillekin!',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Löysin Dixun palvelut netistä. Olimme itse katselleet asuntoa ja netissä tuli Dixu vastaan. Halusimme nimenomaan myydä itse ja Dixun palvelut tarjosivat hyvän tuen tähän hoitaen kaiken asunnon myyntiin liittyvän oheistyön, kuten asiakirjat ja dokumentit.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Kaikki sujui erinomaisesti.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Myimme Dixun kautta kiinteistön. Kuvaaja oli ammattitaitoinen ja hän tuli ripeässä ajassa. Virtuaalikuvat olivat plussaa.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Moni asia oli joustavaa ja helppoa, kuten yhteydenpito Dixun kanssa ja myynti-ilmoituksen luonti. Tosi hyvät kuvat! Sivut voisivat olla saatavilla myös englanniksi, sillä meidän kohteestamme oli pari yhteydenottoa englanniksi ja päädyimme myymään asunnon vain englantia puhuvalle pariskunnalle.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Koko prosessi sujui hyvin. Erityisen hyvää oli, että vastaukset viesteihin ja kysymyksiin sai nopeasti.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Konsepti on hyvä ja hieno haastaja alalle.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Sain hyvää palvelua Dixulta. Konseptinne on erinomainen, asunnon valokuvaus ja virtuaaliesittely olivat laadukkaita. Sain vastaukset kysymyksiini välittömästi ja asunto saatiin myyntiin nopeasti ja vaivattomasti.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Pidin ripeästä, toimivasta palvelusta ja joustavuudesta, kun prosessissa oli pieniä mutkin meistä myyjistä johtuen. En varsinaisesti keksi mitään huomautettavaa. Ehkä digistailattu kuva tuntuu minusta vähän turhalle.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Erittäin ystävällinen, nopea ja osaava asiakaspalvelu. Selkeät, helppokäyttöiset ja hyvännäköiset nettisivut ja myynti-ilmoitukset.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Meidän mielestä Dixun idea ja kokonaispakettien vaihtoehdot olivat erittäin hyvät. Palvelu vastaa tätä päivää ja myyjänä oli hienoa voida ostaa palvelu, josta välittäjä on otettu pois. Eritoten ollaan tosi tyytyväisiä valokuvaajaan ja virtuaaliesittely oli huippu. Myös tuo virtuaalistailaus oli hyvä.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Myimme Dixun kautta kiinteistön. Kuvaaja oli ammattitaitoinen ja hän tuli ripeässä ajassa. Virtuaalikuvat olivat plussaa. Hieman jäin kaipaamaan yksityiskohtaisempia ohjeita.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'On ollut tosi helppoa tehdä ilmoitukseen muutoksia. Myös nopeat vastausajat, jos on ollut jotain kysyttävää.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }, {
    content: 'Hyvää asiakaspalvelua, sain nopeasti vastauksia kysymyksiini. Kiitos! Suosittelen teitä kyllä muille.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_EASY
  }];
  const dixuAndRealtorExperiences = [{
    content: 'Kokemukseni Dixulla oli super hyvä. Kaikki sujui todella vauhdikkaasti, laadukkaasti ja vaivattomasti. Suosittelen Dixua kaikille ja on aivan varmaa, että jos jatkossa minulle tulee asunnon myyntiin tarvetta, niin otan ensimmäiseksi yhteyttä Dixuun.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Nopeasta toiminnasta iso plussa!',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Asunnon myynnin helppous alaa mullistavaa aivan jokaisella osa-alueella. Toivottavasti mahdollisimman moni löytää teidät. Kaikkien kuvien ym. lisäksi myös välittäjä todella ammattitaitoinen. Kokemus 5/5!',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Palvelun helppous ja erittäin kilpailukykyinen hinnoittelu. Valokuvien laatu oli erinomainen ja virtuaaliesittely hyvin toteutettu.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Palvelu oli hyvää ja ystävällistä. Tuli tunne, että emme ole vain yksi asiakas muiden joukossa. Asiat hoituivat mutkattomasti. Myös välittäjä oli hyvä ja asiantunteva. Hän osasi ottaa huomioon lapsiperheen elämäntilanteen näyttöaikoja ehdottaessa. Suosittelisin Dixua.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Asiantuntevaa ja ystävällistä palvelua sekä mukavat ihmiset, joiden kanssa oli kiva asioida. Kiitos teille!',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Aina kiva, kun joku yrittää ravistella juurtunutta alaa muutokseen. Välittäjä hoiti homman kyllä täydellisesti ja myynti meni paremmin kuin uskalsin edes toivoa. Välittäjästä arvosana 6/5.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Tuli vähän yllätyksenä meillekin, että asunto meni niin nopeasti kaupaksi. Tykkäsin erityisesti siitä miten valokuvaaja hoiti kuvaukset. Hän jäi asuntoomme yksin kuvaamaan ja oli osannut hyvin asetella ja siirtää tavaroita pois kuvista. Myös välittäjä oli todella hyvä, saimme enemmän mitä pyysimme.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }, {
    content: 'Oli todella kiva huomata, miten hyvin asunnon myyjää briifataan, käydään askelmerkit tarkasti läpi, vastataan selkeästi ja nopeasti kysymyksiin sekä luodaan turvallinen olo siihen, että Dixu on apuna matkan varrella kaikissa asioissa.',
    customer: 'Asunnon myyjä',
    servicePackage: SERVICE_PACKAGE.DIXU_PLUS
  }];
  return shuffle([...dixuAndMeExperiences, ...dixuAndRealtorExperiences]).map((experience, index) => _objectSpread(_objectSpread({}, experience), {}, {
    imageSrc: IMAGE_RESOURCES[index % IMAGE_RESOURCES.length]
  }));
};