import { useEffect, useRef } from 'react';
const useInterval = function (callback, interval) {
  let initialize = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const intervalIDRef = useRef();
  useEffect(() => {
    if (initialize) {
      callback();
    }
  }, [initialize, callback]);
  useEffect(() => {
    intervalIDRef.current = window.setInterval(callback, interval);
    return () => {
      window.clearInterval(intervalIDRef.current);
    };
  }, [callback, interval]);
};
export default useInterval;