import { useState, useEffect } from 'react';

// Based on: https://codesandbox.io/s/z20gn?file=/pages/index.js:28-447
const useStickyState = _ref => {
  let {
    initialValue,
    defaultValue,
    key,
    allowedValues
  } = _ref;
  const {
    0: value,
    1: setValue
  } = useState(initialValue);
  useEffect(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      const parsedStickyValue = JSON.parse(stickyValue);
      const stickyValueIsNull = parsedStickyValue === null;
      const valueIsAllowed = !allowedValues || allowedValues.includes(parsedStickyValue) || parsedStickyValue === defaultValue;
      if (!stickyValueIsNull || valueIsAllowed) {
        setValue(parsedStickyValue);
      } else {
        setValue(defaultValue);
      }
    } catch (_error) {
      setValue(defaultValue);
    }
  }, [key, defaultValue]);
  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);
  return [value, setValue];
};
export default useStickyState;