import moment from 'moment-timezone';
import { getFullAddress } from "./DixuListingUtils";
import { FINNISH_DATE_FORMAT, OFFER_STATUS } from "./DixuConstants";
export const getPurchaseOfferFileName = listing => {
  return `${getFullAddress(listing)}.pdf`;
};
export const parseBiddingValidity = offer => {
  if (!offer) return {};
  if (!offer.biddingOfferValidity) return {};
  const validity = offer.biddingOfferValidity.toString().split(' ');
  if (validity?.length !== 2) return {};
  return {
    biddingOfferValidityTimeAmount: validity[0],
    biddingOfferValidityTimeUnit: validity[1]
  };
};

// eslint-disable-next-line import/prefer-default-export
export const isOfferExpired = offer => offer.status === OFFER_STATUS.EXPIRED;
export const getBiddingOfferOperatingStartTime = offer => {
  return moment(offer.biddingOfferValidityStart).add(44, 'hours');
};
export const canOperateBiddingOffer = offer => {
  if (!offer || !offer.bidding) {
    return true;
  }
  return getBiddingOfferOperatingStartTime(offer).diff(moment.utc()) < 0;
};
export const getTimeUntilBiddingCanBeAccepted = offer => {
  const {
    biddingOfferValidityEnd,
    biddingOfferValidityStart
  } = offer || {};
  if (!biddingOfferValidityStart || !biddingOfferValidityEnd) {
    return undefined;
  }
  const diff = getBiddingOfferOperatingStartTime(offer).diff(moment.utc(), 'hours', true);
  if (diff > 0) {
    return `${Math.ceil(diff)} tuntia`;
  }
  if (isOfferExpired(offer)) {
    return '-';
  }
  return `0 tuntia`;
};
export const getOfferTerms = _ref => {
  let {
    t,
    purchaseOffer
  } = _ref;
  if (!purchaseOffer || !t) {
    return '-';
  }
  const terms = [];
  const {
    usesCustomDocument,
    conditionCheckBoolean,
    conditionCheckValue,
    ownApartmentSaleByBoolean,
    ownApartmentSaleByDate,
    loanAcquiredByBoolean,
    loanAcquiredByDate,
    otherPrerequisites,
    humidityInspectionBoolean,
    humidityInspectionValue,
    digitalAndPopulationAgencyPermissionBoolean,
    digitalAndPopulationAgencyPermissionByDate
  } = purchaseOffer || {};
  if (usesCustomDocument) {
    terms.push(t('listing:PurchaseOffer:CustomDocumentPrerequisitesText'));
  }
  if (loanAcquiredByBoolean) {
    terms.push(t('listing:PurchaseOffer:LoanAcquiredBy', {
      date: moment(loanAcquiredByDate).format(FINNISH_DATE_FORMAT)
    }));
  }
  if (conditionCheckBoolean) {
    terms.push(t('listing:PurchaseOffer:ConditionCheckResponsible', {
      responsible: t(`common:ConditionCheckTypes.${conditionCheckValue}`)
    }));
    if (humidityInspectionBoolean) {
      terms.push(t('listing:PurchaseOffer.HumidityInspectionTerm', {
        responsible: t(`common:HumidityInspectionValues.${humidityInspectionValue}`)
      }));
    }
  }
  if (ownApartmentSaleByBoolean) {
    terms.push(t('listing:PurchaseOffer:OwnApartmentSaleBy', {
      date: moment(ownApartmentSaleByDate).format(FINNISH_DATE_FORMAT)
    }));
  }
  if (digitalAndPopulationAgencyPermissionBoolean) {
    terms.push(t('listing:PurchaseOffer.DigitalAgencyPermissionByDate', {
      date: moment(digitalAndPopulationAgencyPermissionByDate).format(FINNISH_DATE_FORMAT)
    }));
  }
  if (otherPrerequisites) {
    terms.push(otherPrerequisites.trim());
  }
  const formatted = terms.filter(Boolean).join('\n');
  return formatted || '-';
};