import _objectWithoutProperties from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
const _excluded = ["lng", "subpath"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Router from 'next/router';
import qs from 'qs';
export const filterEmptyQueryParams = queryParams => {
  const filtered = _objectSpread({}, queryParams);
  Object.entries(filtered).forEach(_ref => {
    let [key, value] = _ref;
    if (value == null || value === '' || Array.isArray(value) && value.length === 0) {
      delete filtered[key];
    }
  });
  return filtered;
};
export const toQueryString = query => {
  return qs.stringify(query, {
    skipNulls: true,
    addQueryPrefix: true
  });
};
export const omitReduntantQueryParams = (query, additionalKeys) => {
  const _ref2 = query || {},
    {
      lng: _lng,
      subpath: _subpath
    } = _ref2,
    included = _objectWithoutProperties(_ref2, _excluded);
  if (additionalKeys) {
    additionalKeys.forEach(key => {
      delete included[key];
    });
  }
  return included;
};
export const getReferrerPath = function () {
  let {
    req
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  if (req) {
    const referrer = req.get('Referrer');
    if (referrer) {
      try {
        const url = new URL(referrer);
        const {
          origin,
          pathname,
          search,
          hash
        } = url;
        if (origin === process.env.WEB_URL) {
          return encodeURIComponent([pathname, search, hash].join(''));
        }
      } catch {
        return '';
      }
    }
  }
  if (process.browser) {
    const {
      asPath
    } = Router || {};
    if (asPath) {
      return encodeURIComponent(asPath);
    }
  }
  return '';
};
export const decodeReferrerPath = referrerPath => {
  return referrerPath ? decodeURIComponent(referrerPath) : undefined;
};
export const currentRouteMatches = function (paths) {
  let {
    pathname = Router?.pathname
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!pathname || !paths?.length) {
    return false;
  }
  return paths.some(path => {
    if (path === pathname) {
      return true;
    }
    if (path.endsWith('*') && pathname.startsWith(path.slice(0, -1))) {
      return true;
    }
    return false;
  });
};