import { useEffect } from 'react';
const focusableQuery = ['a[href]', 'button:not([disabled])', 'input:not([disabled])', 'select:not([disabled])', '[tabindex]:not([tabindex="-1"]):not([disabled])', 'textarea:not([disabled])'].join(', ');
const useRestrictFocus = _ref => {
  let {
    getHostElement,
    condition
  } = _ref;
  useEffect(() => {
    const hostElement = getHostElement();
    if (!hostElement || !condition) {
      return undefined;
    }
    let focusDirection;
    const focusDirectionHandler = event => {
      const {
        key,
        shiftKey
      } = event;
      if (key === 'Tab') {
        if (shiftKey) {
          focusDirection = -1;
        } else {
          focusDirection = 1;
        }
      }
    };
    const focusHandler = event => {
      const isChildEl = hostElement.contains(document.activeElement);
      if (!isChildEl) {
        event.preventDefault();
        if (typeof focusDirection !== 'undefined') {
          if (focusDirection === 1) {
            hostElement.querySelector(focusableQuery)?.focus();
          } else {
            const focusableElements = hostElement.querySelectorAll(focusableQuery);
            focusableElements[focusableElements.length - 1]?.focus();
          }
        }
      }
    };
    if (!hostElement.contains(document.activeElement)) {
      hostElement.querySelector(focusableQuery)?.focus();
    }
    document.addEventListener('keydown', focusDirectionHandler, true);
    document.addEventListener('focusin', focusHandler, true);
    return () => {
      document.removeEventListener('keydown', focusDirectionHandler, true);
      document.removeEventListener('focusin', focusHandler, true);
    };
  }, [getHostElement, condition]);
};
export default useRestrictFocus;