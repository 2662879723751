import _defineProperty from "/usr/src/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import DateHolidays from 'date-holidays';
import moment from 'moment-timezone';
import { FINNISH_DATE_FORMAT } from "./DixuConstants";
export const DefaultDateFormat = 'DD.MM.YYYY';
export const DefaultTimeFormat = 'HH.mm';

// Has to be a function due to moment mutability
const getStartOfToday = () => moment(new Date()).startOf('day');
export const disablePastDatesAllowToday = currentDate => {
  return currentDate && currentDate < getStartOfToday();
};
export const disablePastDatesIncludingToday = currentDate => {
  return currentDate && currentDate <= getStartOfToday();
};
export const disableFutureDates = currentDate => {
  return currentDate && currentDate.startOf('day') > getStartOfToday();
};
export const disableWeekends = currentDate => {
  return currentDate && [0, 6].includes(currentDate.day());
};
export const disablePastDatesAndXDaysAfterToday = x => {
  return currentDate => {
    return disablePastDatesIncludingToday(currentDate) || currentDate < getStartOfToday().add(x + 1, 'days');
  };
};
export const timestampToDay = function (timestamp) {
  let format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DefaultDateFormat;
  const formatted = timestamp && moment(timestamp);
  if (formatted?.isValid()) {
    return formatted.format(format);
  }
  return undefined;
};

/**
 * Returns whether given moment is at least X days after today
 * @param {*} x moment date
 */
export const isMinimumXDaysAfterToday = (date, x) => date && moment(date).startOf('day').isSameOrAfter(moment().startOf('day').add(x, 'days'), 'days');
export const isWeekend = date => date && moment(date).day() % 6 === 0;
export const dateToFinnishFormat = date => date ? moment(date).format(FINNISH_DATE_FORMAT) : 'X';
export const timeToFinnishTimezone = time => time ? moment(time).tz('Europe/Helsinki').format('HH:mm') : 'X';
export const disabledPhotographyHours = [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23];

/**
 * `date-holidays` can impact performance quite a bit when used with
 * `DatePicker` so this closure stuff is done to improve performance.
 */
export const dateIsHoliday = (() => {
  let activeCountry = null;
  let activeYear = null;
  let holidaysByMonth = {};
  return function (date) {
    let {
      country = 'fi'
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const year = date.year();
    if (year !== activeYear || country !== activeCountry) {
      activeYear = date.year();
      activeCountry = country;
      const holidays = new DateHolidays();
      holidays.init(country);
      const holidaysForActiveYear = holidays.getHolidays(activeYear);
      holidaysByMonth = holidaysForActiveYear.reduce((acc, cur) => {
        const parsedDate = new Date(cur.date);
        const month = parsedDate.getMonth();
        const dayOfMonth = parsedDate.getDate();
        return _objectSpread(_objectSpread({}, acc), {}, {
          [month]: [...(acc[month] || []), dayOfMonth]
        });
      }, {});
    }
    const result = !!holidaysByMonth[date.month()]?.includes(date.date());
    return result;
  };
})();
export const getNextDay = function () {
  let dayOffset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let {
    excludeWeekend = false,
    excludeHolidays = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const desiredDay = getStartOfToday().add(dayOffset, 'days');
  const excludedDayChecks = [];
  if (excludeWeekend) {
    excludedDayChecks.push(() => [6, 0].includes(desiredDay.day()));
  }
  if (excludeHolidays) {
    excludedDayChecks.push(() => dateIsHoliday(desiredDay));
  }
  if (excludedDayChecks.length) {
    while (excludedDayChecks.some(check => check())) {
      desiredDay.add(1, 'days');
    }
  }
  return desiredDay;
};