const {
  I18nContext,
  Trans,
  useTranslation,
  withTranslation,
  appWithTranslation
} = require('next-i18next');
const sharedOptions = {
  lng: 'fi',
  supportedLngs: ['fi'],
  fallbackLng: 'fi',
  preload: ['fi']
};
module.exports = {
  I18nContext,
  Trans,
  withTranslation,
  appWithTranslation,
  useTranslation,
  sharedOptions
};