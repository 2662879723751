import { useEffect } from 'react';
const usePreventBodyScroll = condition => {
  useEffect(() => {
    if (!condition) {
      return undefined;
    }
    const {
      body
    } = document;
    body.style.overflow = 'hidden';
    body.dataset.preventScrollCount = (Number(body.dataset.preventScrollCount) || 0) + 1;
    return () => {
      body.dataset.preventScrollCount = (Number(body.dataset.preventScrollCount) || 1) - 1;
      if (!Number(body.dataset.preventScrollCount)) {
        body.style.overflow = '';
        body.removeAttribute('data-prevent-scroll-count');
      }
    };
  }, [condition]);
};
export default usePreventBodyScroll;