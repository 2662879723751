const Environment = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  DEMO: 'demo',
  STAGING: 'staging'
};
const getRunEnvironment = () => process.env.ENVIRONMENT || Environment.DEVELOPMENT;
const isProductionEnv = getRunEnvironment() === Environment.PRODUCTION;
const isDemoEnv = getRunEnvironment() === Environment.DEMO;
const isTestEnv = getRunEnvironment() === Environment.STAGING;
const isDevelopmentEnv = !getRunEnvironment() || getRunEnvironment() === Environment.DEVELOPMENT || !Object.values(Environment).includes(getRunEnvironment());
const isStorybookEnv = process.env.STORYBOOK === 'true';
module.exports = {
  Environment,
  getRunEnvironment,
  isProductionEnv,
  isDemoEnv,
  isTestEnv,
  isDevelopmentEnv,
  isStorybookEnv
};