import { useEffect, useRef } from 'react';
const useStickyRef = _ref => {
  let {
    initialValue,
    defaultValue,
    key,
    allowedValues,
    onChange
  } = _ref;
  const valueRef = useRef(initialValue);
  const storeValue = () => {
    window.localStorage.setItem(key, JSON.stringify(valueRef.current));
  };
  const setValueRef = value => {
    valueRef.current = value;
    window.localStorage.setItem(key, JSON.stringify(valueRef.current));
    onChange?.(valueRef.current);
  };
  useEffect(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      const parsedStickyValue = JSON.parse(stickyValue);
      const stickyValueIsNull = parsedStickyValue === null;
      const valueIsAllowed = !allowedValues || allowedValues.includes(parsedStickyValue) || parsedStickyValue === defaultValue;
      if (!stickyValueIsNull || valueIsAllowed) {
        setValueRef(parsedStickyValue);
      } else {
        setValueRef(defaultValue);
      }
    } catch (_error) {
      setValueRef(defaultValue);
    }
    storeValue();
  }, [key, defaultValue]);
  return [valueRef, setValueRef];
};
export default useStickyRef;