import { useEffect } from 'react';

/**
 * Useful for iOS Safari when you need to know the exact real 100vh.
 */
const useViewportHeight = _ref => {
  let {
    condition = true,
    cssVariableName,
    transform = height => height
  } = _ref;
  useEffect(() => {
    if (!condition || !cssVariableName) {
      return undefined;
    }
    const updateHeight = () => {
      const {
        innerHeight
      } = window;
      document.documentElement.style.setProperty(`--${cssVariableName}`, `${transform(innerHeight)}px`);
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => {
      window.removeEventListener('resize', updateHeight);
      document.documentElement.style.setProperty(`--${cssVariableName}`, '');
    };
  }, [condition, cssVariableName]);
};
export default useViewportHeight;